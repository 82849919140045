<template>
    <div class="document-modal modal-bordered">
        <div class="document-modal_body project-scroll" v-viewer="options">
            <div class="document-modal_image" v-for="img in images">
                <img :src="img.image_url">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Documents",
    data() {
        return {
            images: [],
            options: {
                movable: false,
                title: false,
                toolbar: false
            }
        }
    },
    created() {
        this.$http('content').getLicenses()
            .then(res => {
              this.images = this.$payloadHandler(res);
            });
    }
}
</script>

<style lang="scss" scoped>
    @import "../../styles/mixins";

    .document-modal {
        padding: 45px 15px 20px 15px;
        background: white;

        &_image {
          display: flex;
          align-items: center;
          justify-content: center;
          //width: calc(100% - 20px);
          margin: 10px;
          //height: 100%;
          width: 100%;
          height: fit-content !important;

            @include only-md-up {
                height: 500px;
            }

            @include only-xs {
                width: 100%;
            }

            & img {
              cursor: pointer;
              width: 100%;
              height: 100%;

              @include only-xs {
                  height: auto;
                  width: 100%;
              }
            }
        }

        &_body {
            height: 500px;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            overflow-y: auto;
            overflow-x: hidden;
            padding-right: 15px !important;
        }
    }
</style>